<template>
  <div class="mainpage">
    <div class="head">
      <div class="head-title">日志</div>
      <div class="retutnbt" @click="toback">返回</div>
    </div>
    <div class="line"></div>
    <div class="maincontain">
      <el-tabs class="eltabs" v-model="form.type" @tab-click="tabbt">
        <el-tab-pane label="日报" name="0"></el-tab-pane>
        <el-tab-pane label="周报" name="1"></el-tab-pane>
        <el-tab-pane label="月报" name="2"></el-tab-pane>
      </el-tabs>
      <div class="searchcontain">
        <div class="left">
          <div class="contain">
            <div class="maintext">关键字:</div>
            <el-input class="elinput" v-model="form.keyword" placeholder="请输入内容" clearable>
              <i class="el-icon-search el-icon_search" slot="suffix"></i>
            </el-input>
          </div>
          <div class="contain">
            <div class="maintext">批阅状态:</div>
            <el-select class="elinput" v-model="form.status" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="right">
          <el-button class="bt searchbt" @click="search">查询</el-button>
          <el-button class="bt restbt" @click="reset">重置</el-button>
          <template v-if="form.type==0">
            <el-button
              class="bt searchbt"
              :disabled="day_status==0"
              @click="tosubmitreport('check',null)"
            >新增</el-button>
          </template>
          <template v-if="form.type==1">
            <el-button
              class="bt searchbt"
              :disabled="week_status==0"
              @click="tosubmitreport('check',null)"
            >新增</el-button>
          </template>
          <template v-if="form.type==2">
            <el-button
              class="bt searchbt"
              :disabled="month_status==0"
              @click="tosubmitreport('check',null)"
            >新增</el-button>
          </template>
        </div>
      </div>
      <div class="main-contain">
        <div class="contain">
          <el-table
            ref="singleTable"
            :data="tableData"
            highlight-current-row
            style="width: 100%"
            class="Table"
            :header-cell-style="{
              'font-size': '15px',
              color: '#666666',
              'font-weight': 'bold',
              background: '#F7F7F7',
            }"
            :row-style="{
              'font-size': '15px',
              color: '#222222',
              'font-weight': '400',
            }"
          >
            <el-table-column prop="title" label="标题"></el-table-column>
            <el-table-column prop="created_at" label="提交日期">
              <template slot-scope="scope">{{ scope.row.created_at | dataformat }}</template>
            </el-table-column>
            <el-table-column prop="date2" label="总结时间">
              <template slot-scope="scope">
                <template v-if="form.type == 1">
                  {{ scope.row.start_date | dataformat }} ~ {{
                  scope.row.end_date | dataformat
                  }}
                </template>
                <template v-else>{{ scope.row.start_date | dataformat }}</template>
              </template>
            </el-table-column>
            <el-table-column prop="status" label="批阅状态">
              <template slot-scope="scope">{{ scope.row.status | statusformat }}</template>
            </el-table-column>
            <el-table-column prop="reply_content" label="评语"></el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <div class="item-right">
                  <div class="bt" @click="checkbt(scope.row)">查看</div>
                  <div class="line2" v-if="scope.row.status == 0 || scope.row.status == 2"></div>
                  <div
                    class="bt"
                    v-if="scope.row.status == 0 || scope.row.status == 2"
                    @click="tosubmitreport('edit',scope.row)"
                  >修改</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="elpagination">
            <div class="elpagination-title">共{{ total }}条</div>
            <el-pagination
              background
              layout="prev, pager, next"
              :total="total"
              :page-size="10"
              :current-page="form.page"
              @current-change="pageChange"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <Customdialog ref="customdialog" type="max" width="60%" title="实习日志" :showclose="true">
      <div slot="dialogbody" class="dialogbody">
        <Loglook ref="loglook"></Loglook>
      </div>
      <div slot="dialogfooter" class="dialogfooter"></div>
    </Customdialog>
  </div>
</template>

<script>
const statusmap = ["未批阅", "已批阅", "批阅退回"]
import { getlistpracticestudentreport } from "@/api/practicestudentreport"
import Customdialog from "@/components/customdialog.vue"
import dayjs from "dayjs"
import Loglook from "./loglook"

export default {
  name: "mainpage",
  components: {
    Customdialog,
    Loglook,
  },

  data () {
    return {
      total: 0,
      form: {
        keyword: "",
        type: "0",
        practice_plan_id: "",
        status: "",
        page: 1,
        per_page: 10
      },
      testimage: require("@/assets/test.png"),
      icon1: require("@/assets/coursedetails/文件类型-标准图-视频文件.png"),
      icon2: require("@/assets/coursedetails/矢量智能对象 (1).png"),
      tableData: [],
      options: [
        { value: 0, label: "未批阅", },
        { value: 1, label: "已批阅", },
        { value: 2, label: "批阅退回", },
      ],
      dialogTitle: "日志",
    }
  },
  computed: {
    practice_plan_id () {
      return this.$route.query.practice_plan_id
    },
    // 日报 周报 月报 (1 可以提交 0 不可以)
    day_status () {
      return this.$route.query.day_status
    },
    week_status () {
      return this.$route.query.week_status
    },
    month_status () {
      return this.$route.query.month_status
    },
  },
  filters: {
    statusformat (val) {
      return statusmap[val]
    },
    dataformat (value) {
      if (!value) return ""
      return dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss")
    },
  },
  created () {
    this.form.practice_plan_id = this.practice_plan_id
    this.getlistpracticestudentreport()
  },
  methods: {
    toback () {
      this.$router.push({
        path: "/home/personalcenter/mainpage",
        query: {
          id: 6,
          componentId: "Myinternship",
        },
      })
    },
    todetail () {
      this.$router.push({
        path: "/home/personalcenter/interactiveqadetails",
      })
    },
    tosubmitreport (type, row) {
      if (row) {
        this.$router.push({
          path: "/home/personalcenter/editorlog",
          query: {
            type: type,
            id: row.id,
            practice_plan_id: row.practice_plan_id,
            student_id: row.student_id ? row.student_id : ''
          },
        })
      } else {
        this.$router.push({
          path: "/home/personalcenter/editorlog",
          query: {
            type: type,
            id: '',
            practice_plan_id: this.practice_plan_id,
            student_id: '',
            titleType: this.form.type
          },
        })
      }

    },
    checkbt (row) {
      this.dialogTitle = row.title
      this.$refs.customdialog.dialogopenbt()
      this.$nextTick(() => {
        this.$refs.loglook.initdata(row)
      })
    },
    search () {
      this.getlistpracticestudentreport()
    },
    reset () {
      this.form.keyword = ""
      this.form.status = ""
      this.form.page = 1
      this.form.per_page = 10
      this.getlistpracticestudentreport()
    },
    pageChange (val) {
      this.form.page = val
      this.getlistpracticestudentreport()
    },
    tabbt () {
      this.reset()
    },
    getlistpracticestudentreport () {
      this.tableData = []
      getlistpracticestudentreport(this.form)
        .then((response) => {
          if (response.code === 0) {
            this.tableData = response.data.data
            this.total = response.data.total
          }
        })
        .catch((error) => {
          //console.log(error);
        })
    },
  },
};
</script>
<style scoped lang="scss">
.mainpage {
  background: #fff;
  min-height: calc(100vh - 240px);
  .head {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;
    padding-top: 10px;
    .head-title {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #3d84ff;
      user-select: none;
      white-space: nowrap;
    }
    .retutnbt {
      margin-right: 40px;
      width: 64px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background: #3d84ff;
      border-radius: 4px;
      color: white;
      user-select: none;
      cursor: pointer;
      font-size: 14px;
    }
  }
  .line {
    margin-top: 14px;
    margin-bottom: 4px;
    border-bottom: 2px solid #cccccc;
  }
  .maincontain {
    border-radius: 4px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    background: #fff;
    align-items: center;
    .eltabs {
      ::v-deep .el-tabs__item {
        font-size: 20px;
        padding: 0px;
        width: 150px;
        text-align: center;
      }
    }

    .searchcontain {
      margin-top: 20px;
      height: 56px;
      background: #fcfcfc;
      border: 1px solid #ececec;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 20px;
      padding-right: 20px;
      .left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .contain {
          margin-right: 20px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .maintext {
            margin-right: 10px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #888888;
          }
          .elinput {
            width: 200px;
            ::v-deep .el-input__suffix {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
      .right {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .bt {
          width: 64px;
          height: 38px;
          line-height: 38px;
          text-align: center;
          background: #ffffff;
          padding: 0px;
        }
        .restbt {
          margin-right: 10px;
          background: white;
          color: #3d84ff;
        }
        .searchbt {
          background: #3d84ff;
          color: white;
        }
      }
    }
    .main-contain {
      margin-top: 20px;
      width: 100%;
      height: 100%;
      .contain {
        width: 100%;
        height: 100%;
        .Table {
          width: 100%;
          .item-left {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .title {
              margin-left: 10px;
              font-size: 15px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #222222;
            }
          }
          .item-middle {
            .title {
              font-size: 15px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #222222;
            }
          }

          .item-right {
            display: flex;
            justify-content: center;
            align-items: center;
            .bt {
              font-size: 15px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #0773fc;
              user-select: none;
              cursor: pointer;
            }
            .line2 {
              margin-right: 8px;
              margin-left: 8px;
              height: 14px;
              border-right: 2px solid #cccccc;
            }
          }
        }
        .elpagination {
          padding: 40px 0;
          display: flex;
          justify-content: center;
          align-items: center;
          .elpagination-title {
            margin-right: 4px;
          }
        }
      }
    }
  }
}
</style>
